import dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat')
const utc = require('dayjs/plugin/utc')

dayjs.extend(customParseFormat)
dayjs.extend(utc)


export function toISODateString(date) {
    const inputDate = dayjs(date, 'DD-MM-YYYY')
    if(date && inputDate.isValid())
    {
        return inputDate.toISOString()
    }    
    return null    
}
 

export function formatUtcDateTimeToLocalDate(utcDateTime) {
    return utcDateTime
        ? dayjs
              .utc(utcDateTime)
              .local()
              .format('DD-MM-YYYY')
        : null
}
 