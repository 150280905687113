<template>
  <div id="zoom-container">
    <recess-card  
        v-for="(newFeature, newFeatureIndex) in newFeatures"
        :class="[`qa-whats-new-item-${newFeatureIndex}`, {'mb-4': newFeatures.length > 1}]"
        variant="variant1"
        :key="`new-feature-${newFeatureIndex}`"
    >
        <div class="row mt-0" v-if="newFeature.id">
            <div class="col-12 d-flex justify-content-end">
                <span :class="`c-icon c-icon__bin qa-new-feature-delete-button-${newFeatureIndex}`" 
                    @click="removeFeature(newFeature.id)"
                ></span>
            </div>
        </div>
        
        <div class="row my-0">
            <div class="col-6">
                <recess-input  
                    v-model="newFeature.title"
                    :label-text="SHARED.WhatsNewPage.Form.Title.Label"
                    class="qa-new-feature-title"
                    :error-message="titleRequired(newFeatureIndex)" 
                /> 
                <recess-textarea-input 
                    v-model="newFeature.description"
                    :label-text="SHARED.WhatsNewPage.Form.Description.Label"
                    class="mt-3 qa-new-feature-description" 
                    maxlength="400"
                    :placeholder-text="SHARED.WhatsNewPage.Form.Description.Placeholder" 
                    :error-message="descriptionRequired(newFeatureIndex)" 
                /> 

                <div class="d-flex mt-3"> 
                    <recess-date-picker
                        v-model="newFeature.startDate"
                        :default-value="newFeature.startDate"
                        :label-text="SHARED.WhatsNewPage.Form.StartDate.Label"
                        class="qa-new-feature-start-date"
                        :error-message="startDateErrors(newFeatureIndex)"  
                    />
                    <recess-date-picker
                        v-model="newFeature.endDate"
                        :default-value="newFeature.endDate"
                        :label-text="SHARED.WhatsNewPage.Form.EndDate.Label"
                        class="ml-3 qa-new-feature-end-date"
                        :error-message="endDateErrors(newFeatureIndex)"  
                    /> 
                </div> 
                <recess-checkbox-input
                    v-model="newFeature.isVisibleOnAccount"
                    :default-checked="newFeature.isVisibleOnAccount"
                    :label-text="SHARED.WhatsNewPage.Form.UiVisibility.Account"
                    name="select-visibility-account"
                    class="qa-select-visibility-account mt-3 u-font-size-14"
                    variant="secondary"
                />
                <recess-checkbox-input
                    v-model="newFeature.isVisibleOnProvider"
                    :default-checked="newFeature.isVisibleOnProvider"
                    :label-text="SHARED.WhatsNewPage.Form.UiVisibility.Provider"
                    name="select-visibility-provider"
                    class="qa-select-visibility-provider mt-3 u-font-size-14"
                    variant="secondary"
                />
                <div class="mt-3 u-font-size-14"> {{ SHARED.WhatsNewPage.Form.UiVisibility.Title }}</div>
            </div>
            <div class="col-6">
                <recess-file-upload 
                    :label-text="SHARED.WhatsNewPage.Form.ImageUpload.Label"
                    :placeholder-text="SHARED.WhatsNewPage.Form.ImageUpload.Placeholder"
                    max-size-file="1044000"
                    :max-file-to-upload="1"
                    uploadedFileStyle="variant3"
                    :allowed-extensions="['png', 'jpg']"
                    class="mb-3 qa-new-feature-file-upload"
                    :custom-error-message="fileUploadRequired(newFeatureIndex)"
                    @upload="(newValue) => uploadImage(newFeature, newValue)"
                    ref="fileUpload"
                />  
                <div class="c-whats-new__thumbnail"> 
                    <image-zoom  
                        :src="dynamicImage(newFeature.image, newFeature.imageUrl)"
                        :alt="newFeature.title" 
                        :close-on-background-click="true"
                    />
                </div>
                
            </div>
            <div class="col-12 d-flex justify-content-end mt-4">
                <recess-button
                    :title="SHARED.Actions.Save"
                    variant="secondary" 
                    :class="`qa-save-button-${newFeatureIndex}`" 
                    @click.prevent.native="saveFeature(newFeature, newFeatureIndex)"
                />
            </div>
        </div>
    </recess-card>
  </div>
</template>

<script> 
import { SHARED } from "../../constants/EdumsSharedConstants.json";
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators' 
import { isRequiredErrorMessage, dateRangeHasErrorMessage, isInvalidErrorMessage, isDateInThePast, articleErrorAction } from "../../constants/validationMessageHelper.js";
 
import { isEndDateBiggerThanStartDate, isValidDate, isDateInTheFuture } from '../../../edums-provider/src/utils/validationHelpers'
  
import { toISODateString, formatUtcDateTimeToLocalDate } from '../../../shared/mixins/dateTimeHelper'

import ImageZoom from "./ImageZoom.vue";


export default {
    name: "NewFeature",
    mixins: [validationMixin], 
    components: {
        ImageZoom
    },
    validations: {
        newFeatures: { 
            $each: {
                title: { required },
                description: { required }, 
                image: {
                    required: requiredIf(function (item) { 
                        return !item.image && !item.id
                    }),
                 }, 
                startDate: {
                    required,
                    isValidDate
                },
                endDate: {
                    isValidDate,
                    isValidEndDate(_, newFeature) { 
                        return isEndDateBiggerThanStartDate(newFeature.startDate, newFeature.endDate)
                    },
                    isInTheFuture(dateString) {
                        return isDateInTheFuture(dateString)
                    }
                }
            }
        }
    }, 
    props: {
        whatsNewItems: {
            type: [Array, Object],
            default: () => {
                return []
            }
        }
    },  
    data() {
        return {
            SHARED, 
            newFeatures: this.whatsNewItems
        }
    },
    watch: {
        whatsNewItems: {
            handler() { 
                this.whatsNewItems.forEach((whatsNewItem)=> {  
                    whatsNewItem.startDate = formatUtcDateTimeToLocalDate(whatsNewItem.startDate)
                    if(whatsNewItem.endDate) {
                        whatsNewItem.endDate = formatUtcDateTimeToLocalDate(whatsNewItem.endDate)
                    }
                })

                 this.newFeatures = JSON.parse(JSON.stringify(this.whatsNewItems))

                
            }
        }
    }, 
    methods: {
        dynamicImage(image, imageUrl) { 
            return image ? `data:image/png;base64,${image}` : imageUrl
        },
        uploadImage(newFeature, image) {  
            if (image && image[0]) {
                newFeature.image = image[0].base64FileString
                newFeature.imageUrl = null

                this.dynamicImage(newFeature.image, newFeature.imageUrl)

            } else {
                newFeature.image = null
            }
        }, 
        removeFeature(currentFeatureId) {  
            this.$emit('save-new-items', {itemToDelete: true, currentFeatureId})
        },
        saveFeature(newFeature, newFeatureIndex) { 
            this.$v.$touch()

            if(this.$v.newFeatures.$each[newFeatureIndex].$invalid) {
                const toastNotification = {
                    type: 'error',
                    message: articleErrorAction('geüpdatet')
                }

                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })

                return
            }

            if(!newFeature.id) {
                const objectToPost = {
                    title: newFeature.title,
                    description: newFeature.description,
                    startDate: toISODateString(newFeature.startDate),
                    endDate: newFeature.endDate ? toISODateString(newFeature.endDate) : null,
                    image: newFeature.image,
                    isVisibleOnAccount: newFeature.isVisibleOnAccount,
                    isVisibleOnProvider: newFeature.isVisibleOnProvider
                } 

                this.$emit('save-new-items', {itemToPost: true, objectToPost})  

                this.$v.$reset() 
            } 
             
            else {
                const objectToPatch = this.setObjectToBePatched(newFeature, newFeatureIndex)

                if(objectToPatch.length > 0) {
                    objectToPatch.forEach((property) => { 
                        if(property.path === 'startDate' || property.path === 'endDate') {
                            property.value = toISODateString(property.value)
                        }  
                    }) 

                    this.$emit('save-new-items',  {itemToPatch: true, objectToPatch, currentFeatureId: newFeature.id})
                }
            }
            
            this.$refs.fileUpload[newFeatureIndex].selectedFiles = []
        },
        setObjectToBePatched(currentFeature, currentFeatureIndex) {
            const patchProperties = [
                'title',
                'description',
                'image',
                'startDate',
                'endDate',
                'isVisibleOnAccount',
                'isVisibleOnProvider'
            ]

            const propertiesToPatch = []

            patchProperties.map((property) => {                 
                if (this.whatsNewItems[currentFeatureIndex][property] !== currentFeature[property]) { 
                    return propertiesToPatch.push({
                        value: currentFeature[property],
                        path: property,
                        op: 'replace'
                    })
                }
                return propertiesToPatch
            })
            return propertiesToPatch
        },
        // Validation
        titleRequired(index) {  
            return isRequiredErrorMessage('title', 'Titel', this.$v.newFeatures.$each[index].title)
        },
        descriptionRequired(index) {  
            return isRequiredErrorMessage('description', 'Omschrijving', this.$v.newFeatures.$each[index].description)
        }, 
        startDateErrors(index) {  
            return (
                isRequiredErrorMessage('startDate', 'Datum', this.$v.newFeatures.$each[index].startDate) ||
                isInvalidErrorMessage(SHARED.WhatsNewPage.Form.StartDate.Label, this.$v.newFeatures.$each[index].startDate, 'isValidDate')
            )
        },
        endDateErrors(index) {  
            return (
                isInvalidErrorMessage(SHARED.WhatsNewPage.Form.EndDate.Label, this.$v.newFeatures.$each[index].endDate, 'isValidDate') ||
                dateRangeHasErrorMessage(
                    'maxDate',
                    this.$v.newFeatures.$each[index].endDate,
                    'isValidEndDate',
                    {startDate: 'startdatum', endDate: 'vervaldatum'}
                ) ||
                isDateInThePast(this.$v.newFeatures.$each[index].endDate, 'isInTheFuture')

            )
        }, 
        fileUploadRequired(index) {   
            return isRequiredErrorMessage('image', 'Beeld', this.$v.newFeatures.$each[index].image)
        }
    }
}
</script>
 